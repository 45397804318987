.header {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.container {
  width: 100%;
  // padding-right: var(--bs-gutter-x, 0.75rem);
  // padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.box-shadow-custom {
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.over-view {
  .background-small {
    background-image: url('../images/dashboard/bg-banner-small.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .background-big {
    background-image: url('../images/dashboard/bg-banner-big.png');
    // background-size: contain;
    background-repeat: no-repeat;
  }
  .background-child {
    background-image: url('../images/dashboard/bg-banner-child.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .background-affiliate {
    background-image: url('../images/dashboard/bg-affiliate.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .background-over-view-right {
    background: linear-gradient(180deg, #ebf5ff 0%, #3eabe9 100%);
  }
}

.invoice {
  // margin-bottom: 32px;
  .background-invoice {
    background-image: url('../images/dashboard/bg-invoice.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}
// .module {
//   margin-bottom: 32px;
// }

/* container */
@media only screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    max-width: 960px;
  }
  .about-col {
    flex: 0 0 auto;
    width: 50%;
  }
  .header {
    max-width: 960px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
  .header {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
  .header {
    max-width: 1600px;
  }
}

.show-custom {
  position: relative;
  opacity: 0;
  animation: move-box 1s forwards;
  z-index: 9;
}

@keyframes move-box {
  0% {
    bottom: -70px;
    opacity: 0;
  }
  0% {
    opacity: 0.3;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.footer {
  .card_new {
    .button-read {
      animation: move-out-button 0.3s forwards;
      position: relative;
      right: -110px;
    }
    &:hover {
      .button-read {
        animation: move-button 0.3s forwards;
        position: relative;
      }
    }
  }
}

@keyframes move-button {
  0% {
    right: -100px;
    opacity: 0;
  }

  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes move-out-button {
  0% {
    right: 0;
    opacity: 1;
  }

  100% {
    right: -110px;
    opacity: 1;
  }
}

.grid-box-custom {
  .tear-custom {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .box-content {
    position: relative;
    z-index: 2;
  }
  .border-line {
    &::after {
      content: '';
      position: absolute;
      top: 100px;
      right: 0;
      width: 1px;
      height: 300px;

      background-image: url(../images/affiliates/vector-line.png);
      background-repeat: no-repeat;
    }
  }
}

.box-shadow-custom {
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14);
}
