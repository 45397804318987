/* Bootstrap tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link-outer-container {
    @apply [&_a]:before:inset-0 [&_a]:before:absolute [&_a]:before:bg-transparent;
  }
}
// Component
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'base/reset';
@import 'base/fonts';
@import 'base/animation';
@import 'base/global';
@import 'base/utilities';

@import 'layout/all';

@import 'component/all';
@import 'fonts/all';

@import 'pages/all';
@import 'pages/affiliatesPortal';
