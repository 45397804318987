body {
  overflow: hidden;
}

.portal {
  &-heading {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    color: #0095d9;
  }
}

.button {
  &-rounded {
    border-radius: 999 !important;
  }
}

.detail-key {
  min-width: 170px;
  max-width: 170px;
  font-size: 13px;
  line-height: 20px;
  color: #111827;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.detail-value {
  font-size: 13px;
  line-height: 20px;
  color: #111827;

  max-height: 150px;
  overflow-y: scroll;
  word-break: break-word;

  &-more {
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    word-break: break-all;
  }
}

.modal-confirm {
  .tera-modal-footer {
    justify-content: center;
  }
}

.tab-table {
  font-weight: 500;

  font-size: 13px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.tera-bank-card {
  .swiper-slide.about {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 400px !important;
    background-color: rgb(216, 155, 0);
    border-radius: 6px;
  }

  .swiper-container.about .swiper-wrapper {
    height: auto !important;
  }

  .swiper-container.about {
    position: relative;
    top: 50%;
    left: 50%;
  }

  .swiper-slide .imgBx {
    width: 100%;
    height: 300px;
    overflow: hidden;
  }

  .swiper-slide .imgBx img {
    width: 100%;
  }

  .title-center.about {
    width: 220px;
    margin: 0 auto;
    padding-top: 20px;
  }
}

.modal-card-bank-list {
  .tera-modal-body {
    padding: 0 !important;
  }
  .tera-modal-content {
    background: transparent;
  }
}

.break-word {
  word-break: break-word;
}

.tera-no-control::-webkit-outer-spin-button,
.tera-no-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tera-no-control[type='number'] {
  -moz-appearance: textfield;
}

.rum-raisin {
  font-family: Rum Raisin;
}

.modal-fullscreen {
  width: 100% !important;
  height: 100vh;

  &.tera-modal {
    margin: 0 !important;

    .tera-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100vh;
      width: 100%;
      border-radius: 0;
      overflow: hidden;

      .tera-modal-header {
        flex-shrink: 0;
      }
      .tera-modal-body {
        overflow: auto;
        flex: 1;
      }
      .tera-modal-footer {
        flex-shrink: 0;
        padding-top: 10px;
        padding-bottom: 30px;
        padding-inline: 0;
        margin-inline: 30px;
        border-top: 1px solid #e9e9e9;
      }
    }
  }
}

.text-custom-table {
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .detail-responsive {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas:
      'item1 item5 '
      'item4 item3 '
      'item7 item8'
      'item10 item6'
      'item2 item9'
      'item11 item12';

    @for $i from 1 through 12 {
      .item#{$i} {
        grid-area: item#{$i};
      }
    }
  }
}

.animation-ring {
  animation: ring 0.6s;
}

@keyframes ring {
  0%,
  100% {
    transform: translate(0);
  }
  20% {
    transform: translate(-15px);
  }
  40% {
    transform: translate(15px);
  }
  60% {
    transform: translate(-10px);
  }
  80% {
    transform: translate(10px);
  }
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-text-fill-color: #ffffff;
//   transition: background-color 5000s ease-in-out 0s;
// }

.scrollbar-none {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.btn-primary {
  position: relative;
  border-radius: 99px;
  padding: 10px 20px;
  font-size: 14px;

  color: #fff;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 -4px 4px 0 rgba(0, 0, 0, 0.03);
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 1000%;
    top: 50%;
    left: 50%;
    transition: transform 0.7s ease;

    z-index: -1;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(0deg);
    background: linear-gradient(90deg, #3f83f8, #3f83f8, #53b2e8, #53b2e8);
  }

  &:hover::before {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  &-lg {
    padding: 12px 20px;

    @include respond('desktop') {
      padding: 14px 20px;
    }
  }
}

.module-item::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent);
}

@keyframes shake {
  0%,
  10%,
  20%,
  30%,
  40%,
  45%,
  50%,
  100% {
    transform: rotate(0);
  }
  5%,
  25% {
    transform: rotate(-8deg);
  }
  15%,
  35% {
    transform: rotate(8deg);
  }
}

.shake-animation {
  animation: shake 1s ease-in-out infinite;
  animation-iteration-count: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter {
  animation: fadeIn 2s forwards;
}

.fade-exit {
  animation: fadeOut 2s forwards;
}
