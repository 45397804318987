@mixin respond($breakpoint) {
  @if $breakpoint == desktop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (min-width: 960px) {
      @content;
    }
  }
  @if $breakpoint == mini-tablet {
    @media only screen and (min-width: 640px) {
      @content;
    }
  }
}
